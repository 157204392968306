import React from "react"
import { PageProps } from "gatsby"
import { Footer } from "../components/Footer"
import { Category } from "../entities/Category"
import { Articles } from "../components/Articles"
import { Article as ArticleEntity } from "../entities/Article"
import { Container } from "../components/Container"
import { TinyNavbar } from "../components/TinyNavbar"
import Layout from "../components/Layout"
import { Seo } from "../components/Seo"

const Categories: React.FC<
  PageProps<
    any,
    {
      category: Category & { parent: Category }
      categories: Category[]
      articles: ArticleEntity[]
    }
  >
> = props => {
  const { categories, articles, category } = props.pageContext

  const breadcrumbs = [{ label: category.title, url: category.url }]

  return (
    <Layout>
      <Seo
        title={`Tous les articles de la catégorie ${category.title}`}
        description={category.description}
        breadcrumbs={breadcrumbs}
        url={props.location.href}
      />

      <TinyNavbar categories={categories} obfuscation />

      <Container className="mt-10">
        <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
          {category.title}
        </h2>
        <p className="text-lg prose text-gray-700">
          {category.description}
        </p>
      </Container>

      <Container className="mt-10">
        <Articles articles={articles} />
      </Container>

      <Footer categories={props.pageContext.categories} obfuscation />
    </Layout>
  )
}

export default Categories
